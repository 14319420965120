%hover-effect-shadow {
    box-shadow: 0 0 20px rgba($color: #3f3f3f, $alpha: 0.2);
}
%hover-effect-shadow2 {
    box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.25);
}
%plate-shadow{
    box-shadow: 0 0 30px rgba($color: #3f3f3f, $alpha: 0.1);
    border: 1px solid #ebebeb;
}

%hover-effect-img-transform {
    // transform: scale(1.1);
    transform: none;
}

.cards {
    padding-top: 0.5rem;
}

@mixin card {
    $root: &;

    padding-bottom: 0.5rem;
    border-radius: 5px;

    color: $font-color-base;
    text-decoration: none;

    &__wrapper {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    &__img{
        margin-bottom: $grid-gutter/2;
        height: 15rem;
        border-radius: 5px;

    }
    &__name, &__anons, &__action{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    &__name {
        padding-top: 0.5rem;
        padding-bottom: 0.7rem;
        
        font-family: $ff-h-bold;
        font-size: 1.125rem;
        line-height: 1.4em;
        color: $headings-color;
        text-decoration: none;
        transition: 0.05s ease;
    }
    &__anons {
        line-height: 1.6em;
        font-family: $ff-normal;

        ul {
            padding-left: 20px;
            margin-top: 0;
            margin-bottom: 1.1em;

            li {
                margin-bottom: 3px;
            }
        }
    }
    &__action{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    @include hover-focus-active {
        // border-bottom: 2px solid $color-gray-4;
        cursor: pointer;
        @extend %hover-effect-shadow;

        #{$root}__img {

            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            img {
                @extend %hover-effect-img-transform;
            }
        }
        .btn{
            background-color: transparentize($color-second, 0.2);
        }

        #{$root}__name,
        #{$root}__more {
            text-decoration: none;
        }
    }
}
