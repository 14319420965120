.section-card {
    @include card;

    &__img {
        overflow: hidden;
        // height: 230px;
    }

    &__name {
        padding-top: 1.125em;
        padding-bottom: 1em;

        font-family: $ff-h-medium;
        font-size: 1.125rem;
        text-align: center;
        span{
            text-align: center;
        }
    }
}