.product-card {
    @include card;
    &__img{
        height: 16rem
    }
    &__anons{
        @include hidet-desc;
        height: 6em;
        margin-bottom: 0.5rem;
    }
}
.product-card-short {
    @include card;
    &__img{
        height: 8rem;
        margin-bottom: $grid-gutter/3;
    }
    &__name{
        padding-top: 0.25rem;
        font-size: 1rem;
    }
}
