.modal-form{
    &.wrapper{
        border-radius: 10px;
        padding: 1.7rem 0;
    }
    &__title{
        h2{
            padding-left: 44px;
            padding-right: 44px;
            // border-left: 8px solid $color-second;
        }
    }
    &__form{
        padding-left: 44px;
        padding-right: 44px;
        form{
            padding-top: 0!important;
        }
    }
}