.instead-slider{
    height: 1.5rem;
}

.banner {
    background-color: $color-clouds;
}

.banner {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    &__wrapper{
        padding: 1rem 1.7rem;
        // border-left: 4px solid $color-second;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        border-radius: 5px;
    }
    &__info {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        flex-grow: 0;
        
        &__header{
            margin-top: 0.2em;
            margin-bottom: 0.5em;
        }

        &__note{
            font-family: $ff-normal;
            font-size: 1rem;
            p{
                font-family: inherit;
                font-size: inherit;
            }
        }
        &__btn{
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
    ul{
        @include custom-markers('\f30b')
    }
}

.gallery{
    padding-bottom: 2rem;
    &__item{
        padding-top: $grid-gutter/2;
        padding-bottom: $grid-gutter/2;
        a{
            border-radius: 5px;
        }
    }
    &__nav{
        margin-top: $grid-gutter;
        .slide{
            padding: $grid-gutter/2;
        }
        .slick-slide.slick-active{
            border: 2px solid transparent;
            @include hover-focus-active{
                cursor: pointer;
            }
        }
        .slick-slide.slick-active.slick-current{
            border-color: $color-second-2;
        }
    }
}
